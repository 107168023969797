import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b6236a9 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _1bb66b5d = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _5ae86003 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _03e5364f = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _bad6ef4c = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _8f086bec = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _bbecd49e = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _8cf89394 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _67209b22 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _b2105b04 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1a024028 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _10c8a7f8 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _3a1f3968 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _751cd528 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _3013de78 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _7d9e022c = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _4a4ef490 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _64fba4b2 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _df857bbc = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _540a9d47 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _568c0dbc = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _7b974c59 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/default",
    component: _2b6236a9,
    name: "home___default"
  }, {
    path: "/german",
    component: _2b6236a9,
    name: "home___german"
  }, {
    path: "/default/cart",
    component: _1bb66b5d,
    name: "cart___default"
  }, {
    path: "/default/checkout",
    component: _5ae86003,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _03e5364f,
      name: "billing___default"
    }, {
      path: "payment",
      component: _bad6ef4c,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _8f086bec,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _bbecd49e,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _8cf89394,
      name: "user-account___default"
    }]
  }, {
    path: "/default/Cms",
    component: _67209b22,
    name: "Cms___default"
  }, {
    path: "/default/customer",
    component: _b2105b04,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _1a024028,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _10c8a7f8,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _3a1f3968,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _751cd528,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _3013de78,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _7d9e022c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "addresses-details/create",
      component: _4a4ef490,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "/default/reset-password",
      component: _64fba4b2,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _df857bbc,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _540a9d47,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/default/Faq",
    component: _568c0dbc,
    name: "Faq___default"
  }, {
    path: "/default/Home",
    component: _2b6236a9,
    name: "Home___default"
  }, {
    path: "/default/Page",
    component: _7b974c59,
    name: "Page___default"
  }, {
    path: "/german/cart",
    component: _1bb66b5d,
    name: "cart___german"
  }, {
    path: "/german/checkout",
    component: _5ae86003,
    name: "checkout___german",
    children: [{
      path: "billing",
      component: _03e5364f,
      name: "billing___german"
    }, {
      path: "payment",
      component: _bad6ef4c,
      name: "payment___german"
    }, {
      path: "shipping",
      component: _8f086bec,
      name: "shipping___german"
    }, {
      path: "thank-you",
      component: _bbecd49e,
      name: "thank-you___german"
    }, {
      path: "user-account",
      component: _8cf89394,
      name: "user-account___german"
    }]
  }, {
    path: "/german/Cms",
    component: _67209b22,
    name: "Cms___german"
  }, {
    path: "/german/customer",
    component: _b2105b04,
    meta: {"titleLabel":"My Account"},
    name: "customer___german",
    children: [{
      path: "addresses-details",
      component: _1a024028,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___german"
    }, {
      path: "my-newsletter",
      component: _10c8a7f8,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___german"
    }, {
      path: "my-profile",
      component: _3a1f3968,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___german"
    }, {
      path: "my-reviews",
      component: _751cd528,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___german"
    }, {
      path: "my-wishlist",
      component: _3013de78,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___german"
    }, {
      path: "order-history",
      component: _7d9e022c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___german"
    }, {
      path: "addresses-details/create",
      component: _4a4ef490,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___german"
    }, {
      path: "/german/reset-password",
      component: _64fba4b2,
      alias: "/customer/account/createPassword",
      name: "reset-password___german"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _df857bbc,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___german"
    }, {
      path: "order-history/:orderId",
      component: _540a9d47,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___german"
    }]
  }, {
    path: "/german/Faq",
    component: _568c0dbc,
    name: "Faq___german"
  }, {
    path: "/german/Home",
    component: _2b6236a9,
    name: "Home___german"
  }, {
    path: "/german/Page",
    component: _7b974c59,
    name: "Page___german"
  }, {
    path: "/default/:slug+",
    component: _7b974c59,
    name: "page___default"
  }, {
    path: "/german/:slug+",
    component: _7b974c59,
    name: "page___german"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
